@import "~react-responsive-carousel/lib/styles/carousel.min.css";
@import "src/Variables";

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-root .carousel {
  .slider {
    padding: 30px 0;
  }

  .control-dots .dot {
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background-color: var(--chakra-colors-syncure-blue-Magnolia);
    box-shadow: none;

    &.selected {
      background-color: var(--chakra-colors-syncure-blue-PersianBlue);
    }
  }
}

.datepickerWrapper {
  @media only screen and (max-width: $mobile-max-width) {
    width: 100%;

    .react-datepicker {
      width: 100%;

      .react-datepicker__month-container {
        width: 100%;
      }
    }
  }

  .react-datepicker {
    -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.46);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.26);
    width: 100%;
    border-radius: 0;

    .react-datepicker__header {
      background: none;
    }

    div.react-datepicker__triangle::after {
      border-bottom-color: var(--chakra-colors-syncure-white-White);
    }

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__day {
        color: var(--chakra-colors-syncure-black-Black81);
        min-width: 40px;
        padding: 6px 10px;

        &:hover {
          background: var(--chakra-colors-syncure-blue-Magnolia);
        }
      }

      .react-datepicker__day--disabled {
        color: var(--chakra-colors-syncure-black-Black42);
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        background: var(--chakra-colors-syncure-blue-Magnolia);
        color: var(--chakra-colors-syncure-black-Black81);
      }
    }
  }
}
